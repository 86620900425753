<template>
  <div>
    <div class="bkColor">
      <div class="projectTitle">
        <div class="T">
          {{
            reimbursement.obj.projectName ? reimbursement.obj.projectName + "的项目详情" : reimbursement.obj.userName
          + "的非项目报销详情 "
          }}
        </div>
        <div class="D">
          项目状态：<span>{{ getReviewedStatus(reimbursement.obj.state) }}</span>
        </div>
        <div style="font-size: 14px">流水号: {{ reimbursement.obj.reimbursementNo }}</div>
        <img v-show="reimbursement.obj.state == 1" class="I" src="../../assets/icon/ytg_img.png" alt="" />
        <img v-show="reimbursement.obj.state == 2" class="I" src="../../assets/icon/yjj_img.png" alt="" />
      </div>
      <div class="projectBody">
        <van-field v-model="projectBelong" label="归属单位" readonly />
        <van-field v-model="reimbursement.obj.userName" label="报销人" readonly />
        <div class="chart">
          <div class="title">
            报销明细<van-button round block type="info" native-type="button" style="width: 120px; float: right"
              @click="downloadAll" size="small" v-if="equipment">下载全部文件</van-button>
          </div>
          <div style="overflow-x: auto; overflow-y: auto">
            <table class="table" :border="1" cellspacing="0">
              <tr>
                <td class="label">序号</td>
                <td class="label">费用类别</td>
                <td class="label">详细类别</td>
                <td class="label">金额（元）</td>
                <td class="label">发票编号</td>
                <td class="label">发票数量</td>
                <td class="label">报销时间开始和结束时间</td>
                <td class="label">备注</td>
                <td class="label">附件</td>
              </tr>
              <tr v-for="(item, index) in reimbursement.obj.detailList" :key="index">
                <td class="label">{{ index + 1 }}</td>
                <td class="label">{{ item.reimbursementName }}</td>
                <td class="label">{{ item.detailTypeName }}</td>
                <td class="label">{{ item.price }}</td>
                <td class="label">{{ item.billNo }}</td>
                <td class="label">{{ item.billNum }}</td>
                <td class="label">{{ item.reimbursementTime }}</td>
                <td class="label">{{ item.description }}</td>
                <td class="label">
                  <div style="margin: 0 5px" v-for="it in item.fileList" :key="it.url" @click="reViewFile(it.url)">
                    {{ it.fileName }}
                  </div>
                </td>
                <!-- <td class="label">{{ item }}</td> -->
              </tr>
            </table>
          </div>
          <div v-show="isReviewImg" style="
        position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;background: #323233;z-index: 1;overflow: auto;
          " @click="isReviewImg = false">
            <img :src="reViewImg" alt="" style="
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            " />
          </div>
        </div>
        <van-field v-model="reimbursement.obj.totalPrice" label="合计" readonly />
        <van-field v-model="reimbursement.obj.reason" rows="1" autosize label="报销备注" type="textarea" readonly />
      </div>

      <process-details :list="reimbursement.obj.projectReviewedList" :avatar="reimbursement.obj.avatar"
        :status="reimbursement.status" :copyList="reimbursement.obj.copyList" :createTime="reimbursement.obj.createTime"
        :userName="reimbursement.obj.userName" :routerPath="routerPath"></process-details>


      <!-- <van-goods-action>
        <van-goods-action-icon icon="chat-o" text="评论" @click="isComment = true" />
      </van-goods-action> -->

      <van-field v-show="reimbursement.status == 3 && queryFrom.userId == reimbursement.userId"
        v-model="queryFrom.approvalComment" rows="1" autosize label="审核意见" type="textarea" placeholder="请输入审核意见" />

      <van-popup v-model="isComment" round closeable @click-close-icon="isComment = false">
        <sandBox :formId="reimbursement.belongId" :type="4" :routerPath="routerPath" @succesSend="pageUpdate"
          :promoterId="reimbursement.obj.userId"></sandBox>
      </van-popup>
    </div>
    <div class="footContent">
      <!-- <div class="footButton" @click="pdf = true">
        <van-icon name="description" size="1.5em" />
        导出PDF
      </div> -->
      <div class="footButton" @click="isComment = true">
        <van-icon name="chat-o" size="16px" />
        评论
      </div>
      <div v-if="reimbursement.status == 3 && queryFrom.userId == reimbursement.userId" style="margin-left:auto">
        <van-button @click="getIdea(0)" style="margin-right:20px">审核拒绝</van-button>
        <van-button type="info" @click="getIdea(1)">审核同意</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Toast,
  Field,
  Uploader,
  Popup,
  GoodsAction,
  GoodsActionIcon,
  Dialog,
  Icon,
  Button
} from "vant";
import Vue from "vue";
Vue.use(Toast);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(Dialog);
Vue.use(Icon)
Vue.use(Button)

import { timeChange, projectStatusText } from "../../units/units";
import { reviewedPut } from "../../api/reviewed";
import { getToken } from "../../units/auth";

import { getReviewed } from "../../api/reviewed";

import processDetails from "../processDetails.vue";
import sandBox from "../inputBox/sandBox.vue";
import { ddShare } from "../../units/dd";
export default {
  name: "reimbursement",
  props: {
    // obj: {
    //   typeof: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    id: {
      typeof: Number,
      default: null,
    },
    // status: {
    //   typeof: Number,
    //   default: null,
    // },
    // belongId: {
    //   typeof: String,
    //   default: null,
    // },
  },
  components: {
    processDetails,
    sandBox
  },

  mounted() {
    this.getAuditDetails()
    this.type = this.$route.query.type;
    this.queryFrom.userId = getToken("userId");
    this.isMobile();
    this.routerPath = `/auditDetails?id=${this.id}&type=${this.type}`
  },
  data() {
    return {
      title: "项目报销详情",
      type: null,
      queryFrom: {
        id: null, //项目id
        approvalComment: "", //审批意见
        flag: null, //是否同意 0否
        userId: 0, //审核人id
      },
      reimbursement: {
        obj: {
          belong: null,
          projectName: '',//项目信息
          userName: '',//收款人
          price: '',//收款金额
          description: '',//收款说明
          detailList: [],
          fileList: []//附件
        }

      },
      isReviewImg: false, //是否预览图片
      reViewImg: "", //预览的图片
      typeOption: [],
      userId: "", //当前用户的id
      isComment: false, //是否显隐评论框
      pdf: false,//是否显隐pdf预览
      htmlTitle: '',//导出pdf的文件名
      equipment: false,//判断是否为移动端
      routerPath: '',
      projectBelong: ''
    };
  },
  // watch: {
  //   obj: {
  //     handler(newVal) {
  //       this.reimbursement = newVal;
  //       this.htmlTitle =
  //         this.reimbursement.obj.projectName + "的报销单文件" ||
  //         this.reimbursement.obj.userName + "的报销单文件";
  //     },
  //   },
  // },
  methods: {
    getAuditDetails() {
      getReviewed(this.id).then((res) => {
        if (res.data.code == 200) {
          this.reimbursement = res.data.data;
          this.projectBelong = this.reimbursement.obj.belong == 1 ? '大美' : 
                               this.reimbursement.obj.belong == 2 ? '中创': 
                               this.reimbursement.obj.belong == 3 ? '广云仓' : 
                               this.reimbursement.obj.belong == 4 ? '大丰年' : ''
          // this.projectBelong = this.reimbursement.obj.belong == 1 ? '大美' : '中创'
          this.pageShare()
        }

      });
    },
    reViewFile(url) {
      if (
        url.indexOf(".jpg") !== -1 ||
        url.indexOf(".jpeg") !== -1 ||
        url.indexOf(".png") !== -1
      ) {
        this.isReviewImg = true;
        this.reViewImg = url;
        return;
      }
      this.$router.push({
        path: "/preview",
        query: {
          url: url,
          routerPath: this.routerPath,
        },
      });
    },
    getTime(time) {
      return timeChange(time);
    },
    getReviewedStatus(s) {
      return projectStatusText(s);
    },
    getIdea(val) {
      Dialog.confirm({
        message: `是否${val == 0 ? "拒绝" : "同意"}`,
        confirmButtonColor: "#0092fe",
      }).then(() => {
        this.queryFrom.id = this.id;
        this.queryFrom.flag = val;
        reviewedPut(this.queryFrom).then((res) => {
          if (res.data.code == 200) {
            Toast.success("提交成功");
            this.$router.replace("/auditList");
          }
        });
      });
    },
    isMobile() {
      this.equipment = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
        ? false
        : true;
    },
    downloadAll() {
      this.reimbursement.obj.detailList.forEach((it) => {
        it.fileList.forEach((item) => {
          fetch(item.url)
            .then((res) => res.blob())
            .then((blob) => {
              // 将链接地址字符内容转变成blob地址
              const a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download = item.fileName; // 下载文件的名字
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a); // 下载完成移除元素
            });
        });
      });
    },
    pageUpdate() {
      this.getAuditDetails()
      this.isComment = false
    },
    // paymentRevoke() {
    //   if (this.reimbursements.state > 0 || this.userId !== this.reimbursements.userId) {
    //     return
    //   }
    //   Dialog.confirm({
    //     message: `确定撤销？`,
    //     confirmButtonColor: '#0092fe'
    //   }).then(() => {
    //     paymentDeltel([this.id]).then(res => {
    //       if (res.data.code == 200) {
    //         Toast.success('撤销成功')
    //         this.$router.replace('/pjPayment');
    //       }
    //     })
    //   })
    // }
    pageShare() {
      let name = this.reimbursement.obj.projectName ? this.reimbursement.obj.projectName + '的报销详情' : this.reimbursement.obj.userName + '的报销详情'
      ddShare(this.routerPath, name)
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-cell__title {
  font-weight: 600;
  font-size: 16px;
  color: #2c3e50 !important;
}

/deep/ .van-button {
  border-radius: 6px;
}

.bkColor {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 9vh;
  background: #f6f6f6;
  overflow: auto;
}

.projectTitle {
  width: 100vw;
  background: #fff;
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 2vh;
  position: relative;

  .T {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1.5vh;
  }

  .D {
    color: #f0a239;
    font-size: 1em;
  }

  .I {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 4vh;
    right: 70px;
  }
}

.projectBody {
  background: #fff;
  margin-bottom: 2vh;
}

/deep/ .van-cell {
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

/deep/ .van-cell__title {
  color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
  width: 100vw;
}

/deep/ .van-cell::after {
  border-bottom: none;
}

.fileList {
  width: 100%;
  padding: 10px 16px;
  box-sizing: border-box;
  font-size: 14px;
  color: #797979;

  .title {
    text-align: left;
  }

  .fileImg {
    width: 80px;
    height: 80px;
  }

  .fileName {
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.chart {
  width: 100vw;
  // height: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
}

.chart .title {
  height: 5vh;
  font-size: 1rem;
  text-align: left;
  line-height: 5vh;
}

.chart .table {
  // display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border: #e4e5e7;
}

.chart .label {
  width: 25vw;
  height: 5vh;
  text-align: center;
  line-height: 5vh;
  margin: 0 10px;
  padding: 0 10px;
}

.reviewedList {
  padding: 10px 16px;
  background: #fff;

  .A {
    top: 30px !important;
  }

  .T {
    top: 35px !important;
  }

  .U {
    top: 65px !important;
  }

  .F {
    height: 110px !important;
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.reviewed {
  position: relative;
  height: 160px;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;

  .avatar {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 75px;
    left: 20px;
  }

  .status {
    position: absolute;
    top: 80px;
    left: 100px;
  }

  .time {
    position: absolute;
    top: 80px;
    right: 20px;
    color: #b4b4b4;
  }

  .userName {
    position: absolute;
    top: 110px;
    left: 100px;
    font-size: 1em;
    font-weight: 600;
  }
}


.footContent {
  width: 100vw;
  height: 9vh;
  padding: 0 10px;
  box-sizing: border-box;
  position: fixed;
  // top: 0;
  // right: 0;
  bottom: 0;
  left: 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
}

.footButton {
  width: 60px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
</style>